<template>
  <div class="content">
    <div class="tbl_wrap">
      <div class="tbl_head float_area">
        <div class="float-left">
          <div class="tbl_result">
            <h5>
              사용자 목록<span class="tit_desc"
                >검색결과 (총 {{ state.totalItems }}명)</span
              >
            </h5>
          </div>
        </div>
        <div class="float-right align-items-center">
          <div class="tbl_btnset">
            <form
              class="search-group"
              @submit.prevent="
                getItems({
                  role: 'USER',
                  keyword: state.searchKeyword,
                  page: 1,
                  size: 15,
                })
              "
            >
              <input
                type="text"
                class="form-control"
                placeholder="이메일 키워드"
                v-model="state.searchKeyword"
              />
            </form>
            <button
              type="button"
              class="btn btn-primary"
              @click="clikInsertBtn"
            >
              <i class="mr-5px"
                ><font-awesome-icon
                  class="gnb_icon"
                  type="fas"
                  icon="fa-plus"
                ></font-awesome-icon> </i
              >등록
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              :disabled="!state.checkedItems.length"
              @click="deleteItems"
            >
              선택 삭제
            </button>
          </div>
        </div>
      </div>
      <base-table
        :useNo="true"
        :fields="state.fields"
        :items="state.items"
        :is-edit="false"
        :isCheckbox="true"
        :loading="state.contentLoading"
        :totalItems="state.totalItems"
        :pageSize="state.pageSize"
        :currentPage="state.currentPage"
        @onChecked="onChecked"
      >
      </base-table>
      <nav class="mt-20px">
        <base-pagination
          v-if="state.totalPages > 1"
          :total="state.totalItems"
          :loading="state.contentLoading"
          :currentPage="state.currentPage"
          :pageCount="state.totalPages"
          :perPage="state.pageSize"
          @change="
            (page) =>
              getItems({
                role: state.role,
                keyword: state.searchKeyword,
                page: page,
                size: 15,
              })
          "
        />
      </nav>
    </div>
  </div>
</template>

<script setup>
import { onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
import memberService from "@/services/member.service";
import BasePagination from "@/components/core/BasePagination.vue";
import BaseTable from "@/components/core/BaseTable/BaseTable.vue";

// # hooks
const router = useRouter();

// # state
const state = reactive({
  role: "USER",
  items: [],
  checkedItems: [],
  searchKeyword: "",
  showModal: false,
  contentLoading: true,
  totalItems: 0,
  totalPages: 0,
  currentPage: 1,
  pageSize: 15,
  userId: 0,
  fields: [
    // table thead fields
    {
      key: "email",
      label: "이메일",
      useClick: true,
      width: "40%",
    },
    {
      key: "externalId",
      label: "아이디",
      useClick: true,
      width: "30%",
    },
    {
      key: "createDate",
      label: "가입날짜",
      useClick: true,
      width: "20%",
    },
  ],
});

// # method
// 다건 조회
const getItems = async (params = { role: "USER", page: 1, size: 15 }) => {
  const res = await memberService.findAll(params);
  state.items = res.data.content;
  state.currentPage = params.page;
  state.totalItems = res.data.totalElements;
  state.totalPages = res.data.totalPages;
  state.contentLoading = false;
};
// 다건 삭제
const deleteItems = async () => {
  alert("사용자 정보는 삭제가 불가능합니다.");
};
// 등록버튼 클릭
const clikInsertBtn = async () => {
  alert("사용자 정보는 등록이 불가능합니다.");
};

// 체크
const onChecked = (items) => {
  state.checkedItems = items.map((item) => item.id);
};

// # lifecycle
onMounted(() => {
  getItems();
});
</script>

<style lang="scss" scoped>
.posts {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .post {
    display: flex;
    gap: 5px;
  }
  a {
    color: blue;
  }
}
.search-group {
  display: flex;
  gap: 10px;
}
.filter-wrap {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}
.btn-group {
  display: flex;
  gap: 10px;
  * {
    display: inline-block;
    padding: 5px 10px;
    background-color: #ddda;
    border-radius: 5px;
    color: #666;
    &:hover {
      background-color: #ddd;
    }
  }
}
.tbl_btnset {
  font-size: unset;
  display: flex;
  .search-group {
    button {
      flex-basis: 50px;
    }
  }
}
</style>
